// import axios from "axios";
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API;  // 'https://www.moominvalleypark-booking.com/api/'
import request from "../utils/index";

// 日期 获取价格
export function get_price(date) {
  return request({
    method: "get",
    url: `/product/price?date=${date}`,
  });
}

// 提交旅客信息
export function post_orderInfo(data) {
  return request({
    method: "post",
    url: `/order/pay-type`,
    data,
  });
}

// 提交旅客信息
export function post_orderTopay(data) {
  return request({
    method: "post",
    url: `/order/topay`,
    data,
  });
}

// 获取订单预览信息
export function get_orderInfo(x) {
  return request({
    method: "get",
    url: `/order/info?x=${x}`,
  });
}

export function post_orderPayresult(id, data) {
  return request({
    method: "post",
    url: `/order/pay-result?id=${id}`,
    data,
  });
}

// 服务条款
export function get_service() {
  return request({
    method: "get",
    url: `/term/service`,
  });
}

export function get_privacy() {
  return request({
    method: "get",
    url: `/term/privacy`,
  });
}

export function get_precautions() {
  return request({
    method: "get",
    url: `/term/precautions`,
  });
}

export function get_contact() {
  return request({
    method: "get",
    url: `/term/contact`,
  });
}

export function get_orderQueryOrder(data) {
  return request({
    method: "post",
    url: `/order/query-order`,
    data
  });
}